var controlSizeMemory =
    controlSizeMemory ||
    function memorySizeOf(obj) {
        let bytes = 0;

        const handleObject = (obj) => {
            if (obj instanceof Date) {
                bytes += 8; // Assuming 64-bit timestamp
            } else {
                const objClass = Object.prototype.toString.call(obj).slice(8, -1);
                if (objClass === 'Object' || objClass === 'Array') {
                    for (const value of Object.values(obj)) {
                        sizeOf(value);
                    }
                } else {
                    bytes += 2 * objClass.length;
                }
            }
        };

        const handleNonObject = (obj) => {
            switch (typeof obj) {
                case 'number':
                case 'bigint':
                case 'boolean':
                case 'symbol':
                    bytes += 8;
                    break;
                case 'string':
                    bytes += obj.length * 2;
                    break;
                case 'function':
                    bytes += obj.toString().length * 2;
                    break;
            }
        };

        const sizeOf = (obj) => {
            if (obj !== null && obj !== undefined) {
                if (typeof obj === 'object') {
                    handleObject(obj);
                } else {
                    handleNonObject(obj);
                }
            }
            return bytes;
        };

        return sizeOf(obj);
    };
